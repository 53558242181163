.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	box-shadow: inset 0 1px 2px rgba(#333, .075);
  }
  
.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	overflow: hidden;
	text-align: center;
	white-space: nowrap;
}

.progress-bar-container {
	width: 100%;
	margin-top: 25px; /* Add space between icons and progress bar */
	font-size: 1.5rem;
	height: 2.5rem;
	border-radius: .6rem;
}

.progress-section {
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.progress-icons {
	display: flex;
	justify-content: start;
	position: absolute;
	top: -15px; /* Move the icons above the progress bar */
	width: 100%;
}

.progress-words {
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 25px; /* Move the icons above the progress bar */
	width: 100%;
}

.progress-words div {
	width: 33%;
	font-size: 1rem;
	color: gray;
	text-align: center;
}

.progress-section .icon.text-blue {
	color: #0F79ED;
}

.progress-section .icon.text-yellow {
	color: #f7b924;
}

#order-list .progress-section .icon.text-green {
	color: #3ac47d;
}

.progress-section .bar-blue .progress-bar {
	background-color: #0F79ED;
}

.progress-section .bar-yellow .progress-bar {
	background-color: #f7b924;
}

.progress-section .bar-red .progress-bar {
	background-color: #BA2424;
}

.progress-section .bar-green .progress-bar {
	background-color: #3ac47d;
}

.progress-section .bar-blue:not(:has(.progress-bar)) {
	background-color: #0F79ED;
}

.progress-section .bar-yellow:not(:has(.progress-bar)) {
	background-color: #f7b924;
}

.progress-section .bar-red:not(:has(.progress-bar)) {
	background-color: #BA2424;
}

.progress-section .bar-green:not(:has(.progress-bar)) {
	background-color: #3ac47d;
}

.progress {

  &.progress-bar-sm {
    height: .5rem;
  }

  &.progress-bar-xs {
    height: .3rem;
  }

  &.progress-bar-rounded {
    border-radius: 30px;
  }

}

.progress-bar-animated-alt {
  &.progress-bar,
  .progress-bar {
    position: relative;

    &::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $white;
      animation: progress-active 2s ease infinite;
    }
  }
}

@keyframes progress-active {
  0% {
    opacity: .4;
    width: 0
  }
  100% {
    opacity: 0;
    width: 100%
  }
}

.animate-progress::after {
	content: '';
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	animation: progress-active-2 2s ease;
  }
  
  @keyframes progress-active-2 {
	0% {
	  opacity: 0.75;
	  width: 0;
	}
	100% {
	  opacity: 0;
	  width: 100%;
	}
  }